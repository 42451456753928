import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { Observable } from 'rxjs';

import { ImageTypeUtils } from '@core/shared/data-access';
import { FormDirective, FormsManager, HeaderElementDirective, TypedForm, filterUndefined } from '@core/shared/util';
import {
  AvatarComponent,
  ChangePasswordInputComponent,
  CharacterLimitDirective,
  NavigateBackButtonComponent,
  RootModuleNavComponent,
  SpinnerComponent,
  ViewModule,
} from '@core/ui';
import { Profil } from '@mp/shared/profil/data-access';

import { ProfilFacade } from '../profil.facade';

type EditProfil = ImageTypeUtils.WithImageFile<Pick<Profil, 'vorname' | 'nachname' | 'bild' | 'email'>>;
type ProfilEditFormsState = { 'profil-edit': EditProfil };

@Component({
  selector: 'mp-profil-edit',
  standalone: true,
  templateUrl: './profil-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    AsyncPipe,
    DatePipe,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,

    AvatarComponent,
    ChangePasswordInputComponent,
    CharacterLimitDirective,
    FormDirective,
    HeaderElementDirective,
    NavigateBackButtonComponent,
    RootModuleNavComponent,
    SpinnerComponent,
    ViewModule,
  ],
})
export class ProfilEditComponent implements OnInit {
  readonly form: TypedForm<EditProfil>;

  private lastUploadedImageId?: string;

  readonly isLoading$: Observable<boolean> = this.facade.isLoading$;
  readonly profil$: Observable<Profil | undefined> = this.facade.profil$.pipe(filterUndefined());
  readonly name$: Observable<string> = this.facade.name$;
  readonly image$: Observable<string | undefined> = this.facade.image$;

  constructor(
    fb: UntypedFormBuilder,
    private readonly formsManager: FormsManager<ProfilEditFormsState>,
    private readonly facade: ProfilFacade,
    private readonly destroyRef: DestroyRef,
  ) {
    this.form = this.buildForm(fb);
  }

  private buildForm(fb: UntypedFormBuilder): TypedForm<EditProfil> {
    const editForm = fb.group({
      id: [null],
      vorname: [null, Validators.required],
      nachname: [null, Validators.required],
      email: [{ value: '', disabled: true }],
      bild: [null],
    });

    return this.formsManager.createForm('profil-edit', editForm);
  }

  ngOnInit(): void {
    this.form
      .valueChanges$<File>('bild')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (bild: File) => (bild ? this.uploadImage(bild) : this.resetImage()),
      });
    this.reload();
  }

  private uploadImage(imageFile: File): void {
    if (imageFile && imageFile instanceof File) {
      this.facade
        .uploadProfilePictureAndGetImageId(imageFile)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (imageId: string) => {
            if (imageId) {
              this.lastUploadedImageId = imageId;
            }
          },
        });
    } else if (imageFile && typeof imageFile === 'string') {
      this.lastUploadedImageId = imageFile;
    }
  }

  private resetImage(): void {
    this.lastUploadedImageId = undefined;
  }

  private reload(): void {
    this.facade.reload();
  }

  update(): void {
    const formValue: EditProfil = this.form.value();

    this.facade.update({
      ...formValue,
      bild: this.lastUploadedImageId!,
    });
  }

  cancel(): void {
    this.facade.cancelUpdate();
  }

  changePassword(passwort: string): void {
    this.facade.changePassword(passwort);
  }
}
