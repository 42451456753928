import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ImageLoaderModule, ImageLoaderService } from '@core/shared/data-access';
import { ProfilEffects, ProfilService, profilReducer } from '@mp/shared/profil/data-access';

import { ProfilActionsFacade } from './profil-actions.facade';
import { ProfilFacade } from './profil.facade';

@NgModule({
  imports: [
    StoreModule.forFeature('profil', profilReducer),
    EffectsModule.forFeature([ProfilEffects]),

    ImageLoaderModule.forRoot({ uploadUrl: '/api/uploads/images/' }),
  ],
  providers: [ProfilService, ImageLoaderService, ProfilFacade, ProfilActionsFacade],
})
export class ProfilModule {}
