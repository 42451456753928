import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterLink } from '@angular/router';
import { Observable } from 'rxjs';

import { NavigationModule } from '@core/ui';

import { ProfilActionsFacade } from '../profil-actions.facade';
import { ProfilFacade } from '../profil.facade';

@Component({
  selector: 'mp-profil-display',
  standalone: true,
  templateUrl: './profil-display.component.html',
  styleUrls: ['./profil-display.component.scss'],
  imports: [
    NgIf,
    AsyncPipe,
    RouterLink,
    RouterLink,

    MatIconModule,
    MatMenuModule,
    MatButtonModule,

    NavigationModule,
  ],
})
export class ProfilDisplayComponent {
  get name$(): Observable<string> {
    return this.profilFacade.name$;
  }

  get activeOrganisation$(): Observable<string> {
    return this.profilFacade.activeOrganisation$;
  }

  get image$(): Observable<string | undefined> {
    return this.profilFacade.image$;
  }

  get organisationenLength$(): Observable<number> {
    return this.profilFacade.organisationLength$;
  }

  constructor(private readonly profilFacade: ProfilFacade, private readonly actionsFacade: ProfilActionsFacade) {}

  logout(): void {
    // TODO: Add actual logout logic
    this.actionsFacade.logout();
  }
}
