import { Injectable } from '@angular/core';

import { AuthService, LocalStorageService } from '@core/shared/data-access';
import { NotificationService, ToastDuration, ToastUrgency } from '@mp/shared/data-access';

@Injectable()
export class ProfilActionsFacade {

  constructor(
    private readonly notificationService: NotificationService,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService
  ) {}

  logout(): void {
    this.authService.logout();

    if (this.localStorageService.isAvailable()) {
      if (!this.localStorageService.read<boolean>('keepOrganisationIdAfterLogout', false)) {
        this.localStorageService.removeEntry('keepOrganisationIdAfterLogout');
        this.localStorageService.removeEntry('activeOrganisationId');
      }
    }

    this.notificationService.toastCustom(
      'Du wirst ausgeloggt...',
      ToastDuration.Long,
      ToastUrgency.Info
    );
  }

}
