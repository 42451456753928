<div class="wrapper">
  <div class="col-profile">
    <a routerLink="profil/edit" class="profile-link">
      <mp-nav-profile [user]="name$ | async" [company]="activeOrganisation$ | async" [picture]="image$ | async">
      </mp-nav-profile>
    </a>
  </div>

  <div class="col-profile-actions-button">
    <button mat-icon-button [matMenuTriggerFor]="profileActionsMenu">
      <mat-icon>manage_accounts</mat-icon>
    </button>
  </div>
</div>

<mat-menu #profileActionsMenu="matMenu">
  <button mat-menu-item (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>Abmelden</span>
  </button>
  <button
    mat-menu-item
    [routerLink]="['/active-organisation-selection']"
    *ngIf="((organisationenLength$ | async) ?? 0) > 1"
  >
    <mat-icon>swap_horiz</mat-icon>
    <span>Organisation wechseln</span>
  </button>
</mat-menu>
